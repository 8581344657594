import * as React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";

import $ from "jquery/dist/jquery";
//import {init} from "../../.cache/navigation";

const Switch = (e)=> {
    e.preventDefault();
    var toggleAll = false;
    var delay = 1000; //miliseconds
    var $first = $('.sectList .sect:first');
    $('.sectList .sect:not(.not-hdden)').each(
        function(){
            $(this).addClass('closed');
            $(this).find('.cont').hide();
            $(this).find('.title h2').wrapInner('<a href="#"></a>');
            $(this).find('.title h2 a').click(
                function(){
                    $first.find('.cont').clearQueue();
                    $(this).parents('.title').next('.cont').slideToggle('normal');
                    if(toggleAll){
                        $('.sect.open').not($(this).parents('.sect')).toggleClass('closed open')
                            .find('.cont').slideToggle('normal');
                    }
                    $(this).parents('.sect').toggleClass('closed open');
                    return false;
                });
        });

    function showFirst(){
        $first.addClass('first').find('.cont').show().parents('.sect').toggleClass('closed open');
    }

    if(typeof window !== `undefined`){
        $(window).load(function(){
            showFirst();
        });
    } else {
        showFirst();
    }
}

const FilteredResults = (e) =>{
    e.preventDefault();
    let switcheroo = $('#category').val();
    console.log(switcheroo);
    if(switcheroo === "") {
        $('.sect').each(function (i, v) {
            if ($(v).hasClass('open')) {
                $(v).removeClass('open').addClass('closed').find('.cont').hide();
            }
            if($(v).hasClass('first')) {
                $(v).addClass('open').removeClass('closed').find('.cont').show();
            }
            $(v).show();
        });
    } else {
        $('.sect').each(function (i, v) {
            if ($(v).hasClass('open')) {
                $(v).removeClass('open').addClass('closed');
            }
            $(v).hide().find('.cont').hide();
        });
        if ($('#' + switcheroo).hasClass('open'))
            $('#' + switcheroo).show().find('.cont').show();
        else
            $('#' + switcheroo).show().removeClass('closed').addClass('open').find('.cont').show();
    }
}

const FaqPage = () => (
    <Layout>
        <Seo title={'Answers to existing customers most frequently asked questions'} description={'For a list of frequently asked questions and answers for existing Nemo secured loan customers visit us online. If your question isn\'t answered then contact us today.'} lang={'en-gb'} />
        <div className="threeCol purple cfx">
            <aside id="leftCol">
                <div id="tNav">
                    <ul></ul>
                </div>
            </aside>
            <section id="rightCol">
                <div className="contCol">
                    <div className="contColInr">
                        <div className="bubble purple cfx">
                            <div className="inr">
                                <h1>Help &amp; FAQs</h1>
                            </div>
                            <div className="bot"></div>
                        </div>
                        <p className="leading">
                        </p><p className="leading">If you’re an existing customer, and have a question about your second
                        charge mortgage from Nemo, you might find the answer below.</p>
                        <p></p>
                        <div className="faqOpt cfx blue">
                            <form>
                            <label>I would like to:</label>
                            <select name="category" id="category">
                                <option value="" data-id={'start'}>view all existing customer FAQs.</option>
                                <option value="nemoMortgage" data-id={'nemoMortgage'}>Ask a question about how my
                                    Nemo mortgage works
                                </option>
                                <option value="settlements" data-id={'settlements'}>Ask a question about settlements</option>
                                <option value="statements" data-id={'statement'}>Ask a question about ordering a
                                    statement
                                </option>
                                <option value="overpayments" data-id={'overpayments'}>Ask a question about overpayments</option>
                                <option value="ppi" data-id={'ppi'}>Ask a question about
                                    Payment Protection Insurance
                                </option>
                                <option value="firstMortgage" data-id={'firstMortgage'}>Ask a question about
                                    changing my first mortgage
                                </option>
                            </select>
                            </form>
                            <a href="#" className="input btn btnGo" onClick={(e) => FilteredResults(e)}>
                                <span className="tl"></span>
                                <span className="tr"></span>
                                <span className="bl"></span>
                                <span className="br"></span>
                                Go
                            </a>

                        </div>
                        <div className="sectList cfx">
                            <div className="sect first open" id={'nemoMortgage'}>
                                <div className="title">
                                    <h2><a href="#" onClick={(e) => Switch(e)}>How a Nemo mortgage works, including questions about interest
                                        rates</a></h2>
                                </div>
                                <div className="cont" style={{ display: 'block' }}>
                                    <p>If you have questions about how your monthly payment is calculated, your
                                        outstanding balance, or interest rates, please click the link below for more
                                        information.</p>
                                    <p><a href="/customer-service/moving-home">How a Nemo
                                        mortgage works</a></p>
                                </div>
                            </div>
                            <div className="sect closed" id={'settlements'}>
                                <div className="title">
                                    <h2><a href="#" onClick={(e) => Switch(e)}>Settlements, including obtaining a quote, and settling early</a>
                                    </h2>
                                </div>
                                <div className="cont" style={{ display: 'none' }}>
                                    <p>You can settle your Nemo second charge mortgage at any time. You can find some
                                        more information in the link below.</p>
                                    <p><a
                                        href="/customer-service/settle-my-second-charge-mortgage">Settling
                                        my second mortgage</a></p>
                                </div>
                            </div>
                            <div className="sect closed" id={'statements'}>
                                <div className="title">
                                    <h2><a href="#" onClick={(e) => Switch(e)}>Mortgage statements, including when to expect them,
                                        charges &amp; how to order a new statement </a></h2>
                                </div>
                                <div className="cont" style={{ display: 'none' }}>
                                    <p>Can I order a statement? <br /><br />Yes. You can request a statement to be posted to
                                        you for any time period of your mortgage term. You can find contact details on
                                        our Contact Us page. <br /><br /><br />When will I get a statement?<br /><br />We will
                                            send you a statement every year on the anniversary of your second charge
                                            mortgage. This will show the information about your account for the last
                                            twelve months, your current balance and a value to settle your mortgage. We
                                            recommend you get a new settlement quote from us if you wish to repay your
                                            mortgage early, to ensure that you have an up to date figure.<br /><br />If
                                                you’re in arrears, or whilst arrears related fees and/or interest remain
                                                on your account, we will send you a statement every three months. Some
                                                more information can be found on our Help with Repayments
                                                page.<br /><br /><br />Charges<br /><br />Where activity on your account has
                                                    incurred a fee, this will appear on your statement as a charge. See
                                                    our Tariff of fees and charges in the link below for more
                                                    information on these. <br /><br />A fee can be paid by debit card. If
                                                        not paid, the fee will remain on your outstanding balance and
                                                        will accrue interest, unless it’s a fee relating to being behind
                                                        with payments. Where payment has been received it should show on
                                                        your statement as a payment transaction. For more information
                                                        about fees, including the different types, follow the link
                                                        below.</p>
                                    <p><a href="/our-fees">Tariff of fees</a></p>
                                </div>
                            </div>
                            <div className="sect closed" id={'overpayments'}>
                                <div className="title">
                                    <h2><a href="#" onClick={(e) => Switch(e)}>Overpayments – paying more than your monthly payment </a></h2>
                                </div>
                                <div className="cont" style={{ display: 'none' }}>
                                    <p>It is possible to pay more than your contractual monthly payment on your Nemo second charge mortgage. Sometimes we refer to these as overpayments or capital repayments. If you make an overpayment this will reduce the outstanding capital balance on your account on which interest is charged. If you have any arrears or default fees or interests, an overpayment would first be used to pay these off, and any remaining value would then be deducted from your outstanding capital balance.</p>
                                </div>
                            </div>
                            <div className="sect closed" id={'ppi'}>
                                <div className="title">
                                    <h2><a href="#" onClick={(e) => Switch(e)}>Payment Protection Insurance (PPI), including how to tell if you
                                        were sold PPI </a></h2>
                                </div>
                                <div className="cont" style={{ display: 'none' }}>
                                    <p>Please refer to the below steps to find out whether you have, or have had PPI
                                        with Nemo: <br /><br />1. If your second charge mortgage started after April 2009,
                                            you will not have PPI. <br />2. If the ‘optional payment protection insurance’
                                                box is ‘£0.00’ on your Mortgage Agreement, you were not sold PPI. <br />3.
                                                    If you were sold PPI, your annual statement will show details about
                                                    PPI in the Account Summary section. <br /><br />Click on the link below
                                                        to see information about Payment Protection Insurance (PPI)</p>
                                    <p><a href="/contact-us/complaints">complaints</a></p>
                                </div>
                            </div>
                            <div className="sect closed" id={'firstMortgage'}>
                                <div className="title">
                                    <h2><a href="#" onClick={(e) => Switch(e)}>Changing the first charge mortgage, including remortgaging and
                                        requesting a Deed of Postponement</a></h2>
                                </div>
                                <div className="cont" style={{ display: 'none' }}>
                                    <p>If you want to change your first charge mortgage , then you will need to apply
                                        for a Deed of Postponement. More information can be found in the link below.</p>
                                    <p><a href="/customer-service/remortgaging">Remortgaging</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="fbCol"></div>
            </section>
        </div>
    </Layout>
)

export default FaqPage;